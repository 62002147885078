import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Modal, Button } from 'react-bootstrap';
import smalltalk from 'smalltalk';
import moment from "moment";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import BlockingLoader from "./Common/Loader";

const DisplayResultsTab = ({ slug }) => {  
  const [event, setResults] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [records,setRecords] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [modalPerPage, setModalPerPage] = useState(10);
  
  const handleViewClick = async (row) => {
    setSelectedRow(row);
    const response = await axios.get(`${baseUrl}events/gets3url?event_slug=${slug}&race=${row.race}`);    
    setRecords(response.data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setRecords([])
    setSelectedRow(null);
  };

 const [fetch,setFetch]=useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
      const response = await axios.get(`${baseUrl}events/get-result-event-slug?slug=${slug}`)        
        setResults(response.data.data);
        setFetch(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug,fetch]);
  const handleDownloadClick = async (row) => {
    try{
        const response = await axios.get(`${baseUrl}events/download-result-xlsx?baseFile=${row.baseFile}`, {
          responseType: 'blob',
        });

        const contentType = response.headers['content-type'] || 'application/octet-stream';
        const extensionMap = {
          'application/pdf': 'pdf',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
          'text/plain': 'txt',
          'image/jpeg': 'jpg',
          'image/png': 'png',
        };
        const extension = extensionMap[contentType] || 'bin';

        const defaultFileName = 'Results';
        let fileName = await smalltalk.prompt(
          'Results Download PopUp',
          'File Name (<span> file will be saved in your default download location </span>)',
          defaultFileName
        ) || defaultFileName;

        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.${extension}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
     } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const [loading, setLoading] = useState(false)
  const handleToggle = async (row,cell) => {
    try {
      setLoading(true);
    const response = await axios.put(
        `${baseUrl}events/update-result-isActive?id=${row.id}`,
        {isActive:!row.isActive},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setFetch(true)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Failed to update the backend:', error);
    }
    finally {
      setLoading(false);
    }
  };
  const recordColumns = [
    {
      dataField: "Bib",
      text: "Bib",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Chip Start",
      text: "Chip Start",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Chip time",
      text: "Chip Time",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Distance",
      text: "Distance",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Name",
      text: <div>Name</div>,
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
        width: "300px",
     },
      filter: textFilter({
        placeholder: "Search Name",
      }),
    },
    {
      dataField: "Gender",
      text: <div>Gender</div>,
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
        width: "300px",
     },
      filter: selectFilter({
        options: {
          Male: "Male",
          Female: "Female",
        },
        placeholder: "Select Gender",
        style: {
          width: "auto",
          margin: "auto",
        },
        className: "form-select",
      }),
    },
    {
      dataField: "Pace",
      text: "Pace",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Place",
      text: "Place",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Speed",
      text: "Speed",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Split@2.5KM",
      text: "Split@2.5KM",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Split@5KM",
      text: "Split@5KM",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Split@7.5",
      text: "speed",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Time",
      text: "Time",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "Wave Start",
      text: "Wave Start",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
  
  ]
  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MM-YYYY HH:mm");
  };
  const columns = [
    {
      dataField: "race",
      text: "Race",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "createdAt",
      text: "uploaded At",
      formatter: (cell) => formatDate(cell),
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "300px",
      },
    },
    {
      dataField: 'isActive',
      text: 'isActive',
      formatter: toggleFormatter,
      formatExtraData: {
        onToggle: handleToggle,
      },
      headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center',
        width: "300px",
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="d-flex justify-content-center">
        <div
          className="view-text"
          onClick={() => handleViewClick(row)}
        >
          View
        </div>
        <div
          className="download-text"
          onClick={() => handleDownloadClick(row)}
        >
          Download Backup File
        </div>
      </div>),
      headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center',
      },
    }, 
   ];
  function downloadFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <button onClick={() => formatExtraData.onDownloadClick(row)} style={{ cursor: 'pointer' }}>
        Download
      </button>
    );
  }
   
  function viewIconFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="view-icon" onClick={() => formatExtraData.onViewClick(row)} style={{ cursor: 'pointer' }}>
      &#128065;
    </div>
      );
  }

  function toggleFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <input
        type="checkbox"
        checked={cell}
        onChange={() => formatExtraData.onToggle(row,cell)}
      />
    );
  }
  const rowStyle = {
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    textAlign: "center",
  };
  
  return (
    <div className="content">
      {loading && <BlockingLoader/>}
      <div className="mt-3">
        {event && event.length > 0 ? (
          <PaginationProvider
          pagination={ paginationFactory({
            custom: true,
            sizePerPage:10,
totalSize: event?.length
          }) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <div>
               <div style={{ overflowX:"auto"}}>
                <PaginationListStandalone
                  { ...paginationProps }
                />
                </div>
          <div className="table-responsive">
            <BootstrapTable
              keyField="id"
              data={[...event].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))}
              columns={columns}
              filter={filterFactory()}
              striped
              hover
              condensed
              rowStyle={rowStyle}
              headerClasses="sticky-header"
              { ...paginationTableProps }
            />
          </div>
          </div>
            )
          }
          </PaginationProvider>
        ) : (
          <p className="text-center mt-4 text-danger" style={{ fontWeight: "bold" }}>
            No results to display. Please upload results for this event.
          </p>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Result Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <PaginationProvider
          pagination={ paginationFactory({
            custom: true,
            sizePerPage:10,
totalSize: records?.length
          }) }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <div>
               <div style={{ overflowX:"auto"}}>
                <PaginationListStandalone
                  { ...paginationProps }
                />
                </div>
          <div className="table-responsive">
            <BootstrapTable
              keyField="id"
              data={records}
              columns={recordColumns}
              filter={filterFactory()}
              striped
              hover
              condensed
              rowStyle={rowStyle}
              headerClasses="sticky-header"
              { ...paginationTableProps }
              />
          </div>
          </div>
            )
          }
          </PaginationProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DisplayResultsTab;