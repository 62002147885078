import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from "react-router-dom";
import SidebarEvent from './SidebarEvent';
import DashboardHeader from './DashboardHeader';
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BreadCrumb from './BreadCrumb';
import { useFormik } from 'formik';
import * as Yup from "yup";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ToastComponent from './Common/Toast';
import Sidebar from './Sidebar';
import BlockingLoader from './Common/Loader';

const UploadFinisherBadge = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [loading, setLoading] = useState(false);
    const location = useLocation()
    const slug = new URLSearchParams(location.search).get("slug");
    const [event, setEvent] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}events/get-eventbyslug?slug=${slug}`);
          setEvent(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [slug]);

  const [isOpen, setIsOpen] = useState(false);
  const handleRaceChange = (event) => {
    formik.setFieldValue('race', event.target.value);
  };
  const formik = useFormik({
    initialValues: {
    file: '',
    name: "",
    bib: "",
    eventName: "",
    distance: "",
    date: "",
    speed: "",
    time: "",
    pace: "",
    race: ""
  },
  
  
  validationSchema: Yup.object().shape({
    file: Yup.string().required('File is required'),
    race: Yup.string().required('Race is required'),
  }),
  onSubmit: async (values) => {
    const formData = new FormData();
    formData.append("file", values.file); 
    formData.append("eventId", values.eventId); 
    formData.append("name", values.name); 
    formData.append("bib", values.bib); 
    formData.append("eventName", values.eventName); 
    formData.append("distance", values.distance); 
    formData.append("date", values.date); 
    formData.append("speed", values.speed); 
    formData.append("time", values.time); 
    formData.append("pace", values.pace); 
    formData.append("race", values.race); 
    try {
      setLoading(true);
      await axios.post(`${baseUrl}events/upload-finisherbadge?slug=${event.slug}`, formData, {

        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
        setToastMessage(`Uploaded finisher badge to s3 successfully!`);  
      setShowToast(true);
      setToastVariant('success');
      setTimeout(() => {
        navigate(`/event/${slug}`);
      }, 1000);
  
    } catch (error) {
      setShowToast(true);
      setToastMessage(error.response.data.error);
      setToastVariant('danger');
    }
    finally {
      setLoading(false);
    }
  }
  });
  useEffect(() => {
    const eventId = event ? event?.id : null;
      formik.setFieldValue("eventId", eventId);
  }, [event]);

useEffect(()=>{
  if(showToast){
window.scrollTo({top: 0, behavior:"smooth"})
  }
}, [showToast])

const handleToggle = () => {
  setIsOpen(!isOpen);
  console.log("clicked")
};

  return (
    <>
      <div className='header-margin'></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      {loading && <BlockingLoader />}
      <div className="content">

        <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
          <form
        className="col-xl-7 col-lg-8 mt-30 mx-auto absolute"
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <div className="row">
        <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Race <span className="text-danger">*</span>
                    </label>
                    <select
                      id="race"
                      className="p-2 form-select text-dark-1"
                      name="race"
                      onChange={handleRaceChange}
                      onBlur={formik.handleBlur}
                     value={formik.values.race}
                    >
                      <option value="">Select Race</option>
                      {event?.race?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {formik.touched.race && formik.errors.race && (
                      <div className="text-danger">
                        {formik.errors.race}
                      </div>
                    )}
                  </div>
                </div>
          <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Upload finisher Badge <span className="text-danger">*</span></label>
              <input
                type="file"
                className='form-control text-dark-1'
                id="file"
                name="file"
                 onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
                 onBlur={formik.handleBlur}
                required
              />
              {formik.touched.file && formik.errors.file ? (
                <div className="text-danger">{formik.errors.file}</div>
              ) : null}
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Name Position</label>
              <input
                type="text"
                className='form-control'
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Bib Position</label>
              <input
                type="text"
                className='form-control'
                id="bib"
                name="bib"
                value={formik.values.bib}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Event Name Position</label>
              <input
                type="text"
                className='form-control'
                id="eventName"
                name="eventName"
                value={formik.values.eventName}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Distance Position</label>
              <input
                type="text"
                className='form-control'
                id="distance"
                name="distance"
                value={formik.values.distance}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Date Position</label>
              <input
                type="text"
                className='form-control'
                id="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Speed Position</label>
              <input
                type="text"
                className='form-control'
                id="speed"
                name="speed"
                value={formik.values.speed}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Time Position</label>
              <input
                type="text"
                className='form-control'
                id="time"
                name="time"
                value={formik.values.time}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
                
              />
  </div>
  <div className="col-md-6 mt-4">
            <label className="text-16 text-dark-1 fw-bold mb-2">Pace Position</label>
              <input
                type="text"
                className='form-control'
                id="pace"
                name="pace"
                value={formik.values.pace}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}
              />
  </div>
          </div>
          <div className="row mt-4 text-center">
          <div className="col-12">
            <button
              className="btn btn-primary"
              type="submit"
            >
Upload
            </button>
          </div>
        </div>
          </form>
          </div>
        </div>
<ToastComponent
      showToast={showToast}
      toastHeader="Upload finisher badge"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  )
}

export default UploadFinisherBadge;