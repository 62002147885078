import React from 'react'
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { Toast } from "react-bootstrap";
import Sidebar from "../Sidebar";
import BreadCrumb from "../BreadCrumb";
import DashboardHeader from "../DashboardHeader";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import "bootstrap-icons/font/bootstrap-icons.css";
import BlockingLoader from '../Common/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Modal, Button } from 'react-bootstrap';
const BulkImport = () => {
  const [error, setError] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [toastVariant, setToastVariant] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [event, setEvent] = useState(null);
  const [sheetErrors,setSheetErrors] =useState([]);
  const [warnings, setWarnings] = useState([]);
  const [showWarnings, setShowWarnings] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [communication, setCommunication] = useState(false);
  const slug = params.get("slug");
const renderErrorMessage = (error) => {
  const keys = Object.keys(error).filter(key => key !== 'row');
  return (
    <div key={error.row} className="error-message">
      <strong>Row {error.row}:</strong>
      <ul>
        {error.errors.map((err, idx) => {
          const key = Object.keys(err)[0];
          return (
            <li key={idx}>{key.replace(/([A-Z])/g, ' $1')}: {err[key]}</li>
          );
        })}
      </ul>
    </div>
  );
        }

  const [file, setFile] = useState(null);
  const [loading, setLoading]= useState(false);
  const [runnerData, setRunnerData] = useState([]);
  const fileInputRef = useRef(null);
const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  setFile(selectedFile);
  setRunnerData([]); 
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = jsonSheet[0];
    const rowsData = jsonSheet.slice(1).map(row => {
      const rowData = {};
      const desiredColumns = ['First Name', 'Last Name', 'Email', "Mobile Number", "Gender", "Category", "Date Of Birth", "T-Shirt Size", "Name On The Bib", "Blood Group", "Contact Name", "Contact Number", "Age"];
      headers.forEach((header, index) => {
        if (desiredColumns.includes(header)) {
          rowData[header] = row[index];
        }
      });
      const isEmptyRow = Object.values(rowData).every(value => value === undefined || value === null || value === '');
    
      return isEmptyRow ? null : rowData;
    }).filter(row => row !== null);  
    setRunnerData(rowsData);
  };

  reader.readAsArrayBuffer(selectedFile);
};

const handleBulkImport = async (sendNotification) => {
  if (!file) {
    setError('Please select a file before importing');
    return;
  }
  const formData = new FormData();
  formData.append('file', file);
  formData.append('slug', slug); 
  formData.append('sendNotification', sendNotification);
  setCommunication(sendNotification);
  try {
    setLoading(true);
    const response = await axios.post(`${baseUrl}events/bulk-import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      setToastVariant("success");
      setToastMessage("Runners registration successful!");
      setShowToast(true);
      setShowSuccessModal(true);
      if (response.data.warnings) {
        setWarnings(response.data.warnings);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      //setRunnerData([]);
    } else {
      setToastVariant("danger");
      setToastMessage("Runners registration failed");
      setShowToast(true);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      setSheetErrors(error.response.data.errors);
    } else {
      setToastVariant("danger");
      setToastMessage("Runners registration failed due to an unexpected error.");
      setShowToast(true);
    }
  } finally {
    setLoading(false);
  }
};
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const handleImportCommTriggering = (sendNotification) => {
  setShow(false);
  handleBulkImport(sendNotification);
};
const handleCancel = ()=> {
  setRunnerData([]);
  setWarnings([]);
  if (fileInputRef.current) {
    fileInputRef.current.value = "";
  }
  setSheetErrors([]);
} 
  const fetchData = async () => {
    const response = await axios.get(`${baseUrl}events/get-eventbyslug?slug=${slug}&orderStatus='COMPLETED'`);
    setEvent(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [slug]);
  const handleDownloadClick = (event) => {
    const categoryNames = event?.category && event?.category?.map((cat) => cat.name).join(", ");
    const wb = XLSX.utils.book_new();
    const ws_data = [
      ["First Name", "Last Name", "Email", "Mobile Number", "Gender", "Category", "Date Of Birth", "T-Shirt Size", "Name On The Bib", "Blood Group", "Contact Name", "Contact Number", "Age", "Runner Club"],
      ["John", "Doe", "john.doe@example.com", "1234567890", "Male", categoryNames, "22-01-2024", "S", "ABCD", "B+", "Doe", "0123456789", "10", "Aero Runners"],
      ["Jane", "Smith", "jane.smith@example.com", "0987654321", "Female", categoryNames, "15-05-2023", "M", "XYZ", "O-", "Smith", "9876543210", "25", "Sky Runners"]
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, "SampleTemplate");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "SampleTemplate.xlsx";
    link.click();
  };
  useEffect(()=>{
    if(showToast){
  window.scrollTo({top: 0, behavior:"smooth"})
    }
  }, [showToast])
  const runnerDataWithIndex = runnerData.map((runner, index) => ({ ...runner, _index: index }));

  const columns = [
    {
      dataField: '_index',
      text: 'Index',   
      hidden:true,
    },
    ...(runnerData && runnerData.length > 0
      ? Object.keys(runnerData[0]).map((header) => ({
          dataField: header,
          text: header.replace(/([A-Z])/g, ' $1').trim(),
        }))
      : []
    )
  ];
  const categoryCounts = runnerData.reduce((acc, runner) => {
    const category = runner.Category;
    if (category) {
      acc[category] = (acc[category] || 0) + 1;
    }
    return acc;
  }, {});
  console.log(categoryCounts, "categoryCounts")
  const categoryArray = Object.entries(categoryCounts).map(([category, count]) => ({
    Category: category,
    Count: count,
  }));
 
  useEffect(() => {
    if (warnings.length > 0) {
      const timer = setTimeout(() => {
        setShowWarnings(false);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [warnings]);
const [filteredData, setFilteredData] = useState([]);
const [showModal, setShowModal] = useState(false);
  const handleViewClick = async (categoryName) => {
setFilteredData(runnerData.filter(item=>item.Category === categoryName))
setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setFilteredData([])
    };
    const handleSuccessModalClose = () => setShowSuccessModal(false);
    const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  return (
    <>
    <style>
        {`
          body {
            overflow-x: hidden;
          }
          .sticky-header th {
            position: sticky;
            top: 0px;
            background-color: #fff; 
            z-index: 1; 
          }
          @media (max-width: 767px) {
            .mobile-width {
              width: 50%;
              margin: 0 auto;
            }
          }
          .react-bootstrap-table-pagination {
            position: sticky;
           left: 0%;
            transform: translateX(0%);
            z-index: 1000;
          }
          table.table {
            th {
              padding: 10px 0px 10px 10px;
              line-height: 1;
              font-weight: 500;
              white-space: nowrap;
              text-align:center;
            }
          
            td {
              padding: 10px 0px 10px 10px;
                white-space: nowrap;
                   line-height: 1;
              text-align:center;
            }
          }

        `}
      </style>
      <div className="header-margin"></div>

<DashboardHeader handleToggle={handleToggle} />
<Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
<div className="content">
  <BreadCrumb task={"Bulk Import"} />
        {loading && <BlockingLoader/>}
<div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">

    <div className="ml-40 custom-min-height">
      <div className="mb-10 mt-3 text-end">
        <button className="btn btn-primary" onClick={()=> handleDownloadClick(event)}>
          Download Sample
        </button>
      </div>
      <div className="row form-group align-items-center justify-content-center">
      <div className='col-md-4'>
        <label htmlFor="csvFile" className="form-label">Choose xlsx File</label>

        <input
          type="file"
          className="form-control form-control border"
          id="csvFile"
          accept=".xls, .xlsx"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </div>
      </div>
      {runnerData && runnerData?.length > 0 && 
      <>
      <div className="col-md-5 table-responsive my-3 mx-auto">
        <table className="table border table-bordered text-center">
          <thead>
            <tr>
<th className="p-2 fw-700 bg-light-2" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
  Category
</th>
<th className="p-2 fw-700 bg-light-2" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
  Count
</th>
<th className="p-2 fw-700 bg-light-2" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
  Actions
</th>
</tr>
</thead>
<tbody>
{categoryArray?.map((item=>
  <>

  <tr>
  <td className="p-1" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>{item?.Category}</td>
  <td className="p-1" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>{item?.Count}</td>
  <td><div onClick={()=>handleViewClick(item?.Category)} style={{cursor:"pointer"}}>View</div></td>
  </tr>

  </>
))}
<tr>
  <td className="p-1 fw-700" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>Total</td>
  <td className="p-1 fw-700" colSpan={2} style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>{runnerData?.length}</td>
</tr>
</tbody>
        </table>
        </div>
      <PaginationProvider
                    pagination={ paginationFactory({
                      custom: true,
                      sizePerPage:20,
  totalSize: runnerData?.length
                    }) }
                  >
                    {
                      ({
                        paginationProps,
                        paginationTableProps
                      }) => (
                        <div>
                         <div style={{ overflowX:"auto"}}>
                          <PaginationListStandalone
                            { ...paginationProps }
                          />
                          </div>

                          <div className="table-responsive mt-3" style={{ maxHeight: '500px', overflowY:"auto", overflowX:"auto"}}>
            <BootstrapTable
               keyField="_index"
               data={runnerDataWithIndex}
                 columns={columns}
              bordered={true}
              headerClasses="sticky-header"
              striped
              hover
              condensed
              { ...paginationTableProps }
            />
          </div>
          </div>
    )
  }
</PaginationProvider>
  </>
}
      <div className="d-flex justify-content-center gap-1 mt-3">
      <button
        className="btn btn-secondary"
        onClick={handleCancel}
      >
       Cancel
      </button>
      <button
        className="btn btn-primary"
        onClick={handleShow}
      >
        Import
      </button>
      </div>
      {error && <div style={{ color: 'red', textAlign:"center" }}>{error}</div>}
      {!!sheetErrors &&sheetErrors.length > 0 && 
        sheetErrors.map((error, index) => (
          <div key={index}>
            {renderErrorMessage(error)}
          </div>
        )
      )} 
      {showWarnings && warnings &&
       warnings?.map((warning, index) => (
        <div className='alert alert-warning w-50 mx-auto mt-3' key={index}>
          <strong>Invalid T-Shirt Sizes:</strong> {warning.InvalidTShirtSize.join(", ")}<br />
          <small>{warning.message}</small>
        </div>
      ))}
         {/*{csvData && (
        <div>
          <h3>CSV Data:</h3>
          <pre>{JSON.stringify(csvData, null, 2)}</pre>
        </div>
      )}*/}
      <div style={{ position: "absolute", top: "100px", right: "10px" }}>
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          autohide
          className={`bg-${toastVariant}`}
        >
          <Toast.Header
            closeButton={true}
            className="d-flex justify-content-between align-items-center"
          >
            <strong className="mr-auto">Import Runners</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Entries in {filteredData[0]?.Category} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <PaginationProvider
                    pagination={ paginationFactory({
                      custom: true,
                      sizePerPage:20,
  totalSize: filteredData?.length
                    }) }
                  >
                    {
                      ({
                        paginationProps,
                        paginationTableProps
                      }) => (
                        <div>
                         <div style={{ overflowX:"auto"}}>
                          <PaginationListStandalone
                            { ...paginationProps }
                          />
                          </div>
          <div className="table-responsive mt-3">
            <BootstrapTable
              keyField="id"
              data={filteredData}
              columns={columns}
              striped
              hover
              condensed
              headerClasses="sticky-header"
              { ...paginationTableProps }
                 />
          </div>
          </div>
    )
  }
</PaginationProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to send a notifications to all imported participants?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleImportCommTriggering(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleImportCommTriggering(true)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSuccessModal} onHide={handleSuccessModalClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Bulk Import Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <div className='text-center'>Total runners: <span className='fw-bold'>{runnerData?.length}</span></div>
           <div className='text-center'>{communication ? "Communication triggered while import" : "No communication triggered while import"}</div>
           <div className="col-md-5 table-responsive my-3 mx-auto">
        <table className="table border table-bordered text-center">
          <thead>
            <tr>
<th className="p-2 fw-700 bg-light-2" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
  Category
</th>
<th className="p-2 fw-700 bg-light-2" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>
  Count
</th>
</tr>
</thead>
<tbody>
{categoryArray?.map((item=>
  <>

  <tr>
  <td className="p-1" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>{item?.Category}</td>
  <td className="p-1" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>{item?.Count}</td>
  </tr>

  </>
))}
<tr>
  <td className="p-1 fw-700" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>Total</td>
  <td className="p-1 fw-700" style={{verticalAlign:"middle", whiteSpace:"nowrap"}}>{runnerData?.length}</td>
</tr>
</tbody>
        </table>
        </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
    </div>
    </div>
       
    </>
  
  )
}

export default BulkImport;