import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEvents } from "../../api/events";
import ToastComponent from "../Common/Toast";
import { baseUrl } from "../../apiConfig";
import BlockingLoader from "../Common/Loader";
import Select from 'react-select';
const CreateCoupon = ({setLoading, slug}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const location = useLocation();
  //const slug = new URLSearchParams(location.search).get("slug");
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const { state } = location;
  const row = state && state?.row;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  const [events, setEvents] = useState([]);

  const fetchData = async () => {
    try {
      let allEvents = [];
      let currentPage = 1;
      const perPage = 10;
      let totalEvents = 0;
      const response = await getEvents({ page: currentPage, perPage });
      const totalCountHeader = response.headers.get('x-total-count');
      console.log('Total Count Header:', totalCountHeader);
  
      totalEvents = parseInt(totalCountHeader, 10);
      const totalPages = Math.ceil(totalEvents / perPage);
      console.log(`Total Pages to fetch: ${totalPages}`);

      allEvents = response.data;
      while (currentPage < totalPages) {
        currentPage++;
        const pageResponse = await getEvents({ page: currentPage, perPage });
        allEvents = allEvents.concat(pageResponse.data);
      }
      setEvents(allEvents);
    } catch (error) {
      console.log("Error during fetching events", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(events, "events");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
 
  const handleCategorySelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    formik.setFieldValue("categories", selectedValues);
  };
  
  const categoryOptions = selectedEvent?.category.map(category => ({
    value: category.name,
    label: category.name
  })) || [];
  
  const handleEventChange = (event) => {
    const selectedEventName = event.target.value;
    const selectedEvent = events.find(
      (event) => event.eventName === selectedEventName
    );
    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
      formik.setFieldValue("eventId", selectedEvent.id);
      formik.setFieldValue("eventName", selectedEvent.eventName);
      formik.setFieldTouched("eventName", false);
    } else {
      setSelectedEvent(null);
      formik.setFieldValue("eventId", "");
      formik.setFieldValue("eventName", "");
    }
  };
  useEffect(() => {
    if (row && events.length > 0) {
      const selectedEvent = events.find((event) => event.id === row.eventId);
      setSelectedEvent(selectedEvent);
      formik.setFieldValue("eventName", selectedEvent.eventName);
      formik.setFieldValue("eventId", selectedEvent.id);
    }
  }, [row, events]);
  const handleExpiryChange = (date) => {
    const utcDate = date && new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
      formik.setFieldValue("expiresAt", utcDate);
      formik.setFieldTouched("expiresAt", false);
    }
  const handleExpiryBlur = () => {
    formik.setFieldTouched("expiresAt", true);
  };
  const formik = useFormik({
    initialValues: {
      couponCode: "",
      isUsed: false,
      expiresAt: "",
      maxRunners: null,
      eventName: "",
      remainingRunners: 0,
      discountPercentage: null,
      eventId: "",
      isActive: null,
      categories: [],
      gender: ""
    },

    validationSchema: Yup.object().shape({
      couponCode: Yup.string()
        .required("Coupon code is required")
        .max(10, "Coupon code must not exceed 10 characters"),
      expiresAt: Yup.date()
        .min(new Date(), "Expiration date must be in the future")
        .required("Expiration date is required"),
      maxRunners: Yup.number()
        .required("Max runners is required")
        .integer("Max runners must be an integer"),
      remainingRunners: Yup.number()
        .required("Remaining runners is required")
        .integer("Remaining runners must be an integer"),
        discountPercentage: Yup.lazy((value, context) => {
          if (!row?.isFullDiscount) {
            return Yup.number()
              .required("Discount percentage is required")
              .min(0, "Discount percentage must be at least 0")
              .max(100, "Discount percentage must be at most 100");
          } 
          return Yup.number().notRequired();
        }),
       eventId: Yup.number().required("Event ID is required"),
      isActive: Yup.boolean(),
      eventName: Yup.string().required("Event name is required"),
      categories: Yup.array()
      .min(1, "At least one category must be selected")
      .required("Categories are required"),
      gender: Yup.string().required("Gender is required"),
      isUsed: Yup.boolean(),
    }),

    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (row) {
          await axios.put(
            `${baseUrl}coupons/editcoupon?couponId=${row?.id}`,
            values
          );
          setToastMessage(`Coupon updated successfully!`);
        } else {
          await axios.post(`${baseUrl}coupons/createcoupon`, values);
          setToastMessage(`Coupon created successfully!`);
        }
        setShowToast(true);
        setToastVariant("success");
        setTimeout(() => {
          navigate(`/coupon-dashboard/${slug}`);
        }, 1000);
      } catch (error) {
        console.error("Error creating coupon:", error);
        setShowToast(true);
        setToastMessage(
          row
            ? "Failed to update coupon"
            : "Failed to create coupon. Please try again."
        );
        setToastVariant("danger");
      }
      finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("remainingRunners", formik.values.maxRunners);
  }, [formik.values.maxRunners]);
  useEffect(() => {
    if (row) {
      formik.setValues({
        ...formik.values,
        couponCode: row.couponCode || "",
        expiresAt: row.expiresAt || "",
        maxRunners: row.maxRunners || "",
        //remainingRunners: row.maxRunners || "",
        discountPercentage : row.discountPercentage || null,
        isActive: row.isActive !== undefined ? row.isActive : false,
        gender: row.gender || "",
        categories: row?.categories || [],
      });
    }
  }, [row]);
  useEffect(() => {
    if (showToast) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [showToast]);
  const isEditMode = !!row;
  return (
    <>
      <style>
        {`
      .datepicker-no-outline:focus {
        outline: none;
      }
      .form-input {
        //position: relative;
      
        //display: flex;
      
        label {
          //position: absolute;
          top: 0;
          pointer-events: none;
          font-size: 14px;
        }
      
        textarea,
        input {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
        select {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
      
      input:not(:placeholder-shown) {
        padding-top: 0px;
      }
      textarea:not(:placeholder-shown) {
        padding-top: 5px;
      }
        textarea:focus ~ label,
        textarea:valid ~ label,
        input:focus ~ label,
        input:valid ~ label {
          transform: translateY(-10px);
        }
      }

      `}
      </style>
      <div className="content">
        <div className="container p-3">
          <div className="row y-gap-20 justify-center items-center">
            <form
              className="col-xl-7 col-lg-8 mx-auto"
              id="reg"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
            >
              <div className="row x-gap-40 y-gap-20">
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Event Name <span className="text-danger">*</span>
                    </label>
                    <select
                      id="eventName"
                      className="p-2 form-select text-dark-1"
                      name="eventName"
                      onChange={handleEventChange}
                      onBlur={formik.handleBlur}
                      value={selectedEvent ? selectedEvent.eventName : ""}
                    >
                      <option value="">Select</option>
                      {events?.map((event, index) => (
                        <option key={index} value={event.eventName}>
                          {event.eventName}
                        </option>
                      ))}
                    </select>
                    {formik.touched.eventName && formik.errors.eventName && (
                      <div className="text-danger">
                        {formik.errors.eventName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
    <div>
      <label className="text-16 text-dark-1 fw-bold">
        Categories <span className="text-danger">*</span>
      </label>
      <Select
                    isMulti
                    value={formik.values.categories.map(cat => ({ value: cat, label: cat }))}
                    options={categoryOptions}
                    onChange={handleCategorySelect}
                    isDisabled={!formik.values.eventName}
                  />
      {formik.touched.categories && formik.errors.categories && (
                      <div className="text-danger">
                        {formik.errors.categories}
                      </div>
                    )}
    </div>
  </div>
  <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="p-2 form-select text-dark-1"
                name="gender" 
                id="gender" 
                value={formik.values.gender || ""}
                 onChange={(e) => {formik.handleChange(e)
                }}
                  onBlur={formik.handleBlur} >
                  <option value="">Please Select</option>
                  <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="Both">Both</option>
                </select>                                
                {formik.touched.gender && formik.errors.gender && (
                  <div className="text-danger">{formik.errors.gender}</div>
                )}
              </div>
  </div>
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Coupon Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="couponcode"
                      name="couponCode"
                      value={formik.values.couponCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={isEditMode}
                      required
                    />
                  </div>
                  {formik.touched.couponCode && formik.errors.couponCode ? (
                    <div className="text-danger">
                      {formik.errors.couponCode}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mt-4">
                  <div className="">
                    <label className="text-16 text-dark-1 fw-bold">
                      Expiry Date <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <div
                        className="form-control"
                        style={{ lineHeight: "40px" }}
                      >
                        <DatePicker
                          selected={formik.values.expiresAt}
                          onChange={handleExpiryChange}
                          onBlur={handleExpiryBlur}
                          dateFormat="dd/MM/yyyy"
                          className="border-0 datepicker-no-outline"
                        />
                      </div>
                    </div>
                  </div>
                  {formik.touched.expiresAt && formik.errors.expiresAt ? (
                    <div className="text-danger">{formik.errors.expiresAt}</div>
                  ) : null}
                </div>

                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Max Runners <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="maxRunners"
                      name="maxRunners"
                      value={formik.values.maxRunners}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                  </div>
                  {formik.touched.maxRunners && formik.errors.maxRunners ? (
                    <div className="text-danger">
                      {formik.errors.maxRunners}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-input">
                    <label className="text-16 text-dark-1 fw-bold">
                      Discount Percentage {row?.isFullDiscount ? "" : <span className="text-danger">*</span>}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="discountPercentage"
                      name="discountPercentage"
                      value={formik.values.discountPercentage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={row?.isFullDiscount}
                      required
                    />
                  </div>
                  {formik.touched.discountPercentage &&
                  formik.errors.discountPercentage ? (
                    <div className="text-danger">
                      {formik.errors.discountPercentage}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mt-4">
                  <div>
                    <label className="text-16 text-dark-1 fw-bold">
                      Is Active <span className="text-danger">*</span>
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isActive"
                      id="inlineRadio1"
                      value="true"
                      checked={formik.values.isActive === true}
                      onChange={() => formik.setFieldValue("isActive", true)}
                    />
                    <label className="form-check-label text-dark-1" htmlFor="inlineRadio1">
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="isActive"
                      id="inlineRadio2"
                      value="false"
                      checked={formik.values.isActive === false}
                      onChange={() => formik.setFieldValue("isActive", false)}
                    />
                    <label className="form-check-label  text-dark-1" htmlFor="inlineRadio2">
                      No
                    </label>
                  </div>
                  {formik.touched.isActive && formik.errors.isActive ? (
                    <div className="text-danger">
                      {formik.errors.isActive}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row mt-4 text-center">
                <div className="col-12">
                  <button className="btn btn-primary" type="submit">
                  {!row ? "Coupon Create" : "Update Coupon"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastComponent
      showToast={showToast}
      toastHeader={row ? "Coupon Update" : "Coupon Create"}
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  );
};

export default CreateCoupon;
