import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import BreadCrumb from "./BreadCrumb";
import BootstrapTable from "react-bootstrap-table-next";
import Sidebar from "./Sidebar";
import parse from "html-react-parser";
import BlockingLoader from "./Common/Loader";

const SingleEvent = () => {
  const { slug } = useParams();
  // const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState();
  const [eventName, setEventName] = useState();
  const [loading, setLoading] = useState(false);

  // const fetchData = async () => {
  //   // todo implement try catch
  //   setLoading(true);
  //   const response = await axios.get(`${baseUrl}events/get-results`);
  //   setEvents(response.data);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const [event, setEvent] = useState(null);
  const [description, setDescription] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);

        // TODO use only event, no need of extra states
        setEventId(response.data.id);
        setEventName(response.data.eventName);
        setDescription(parse(response?.data?.aboutEvent));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    { dataField: "name", text: "Category" },
    { dataField: "amount", text: "Registration Amount" },
  ];

  const rowStyle = {
    verticalAlign: "middle",
    textAlign: "center",
  };
  console.log(event, "event");
  return (
    <>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />

      <div className="content">
        <BreadCrumb event={event} slug={slug} />     

        <div className="d-flex flex-column flex-md-row justify-content-center gap-md-5 p-4">
          <Link
            to={`/upload-certificate?slug=${slug}`}
            className="btn btn-primary border mb-3 mobile-width"
          >
            Certificate Upload
          </Link>
          <Link
            to={`/upload-finisherbadge?slug=${slug}`}
            className="btn btn-primary border mb-3 mobile-width"
          >
            Finisher Badge Upload
          </Link>
          <Link
            to={`/bibgeneration/${slug}`}
            state={{ eventId }}
            className="btn border btn-primary mb-3 mobile-width"
          >
            Bib Generation
          </Link>
          <Link
            to={`/crm/${slug}`}
            state={{ eventId, eventName }}
            className="btn border btn-primary mb-3 mobile-width"
          >
            CRM
          </Link>
          <Link
            to={`/results/${slug}`}
            state={{ eventId, eventName }}
            className="btn border btn-primary mb-3 mobile-width"
          >
            Results
          </Link>
          {/* <Link to={`/upload-results?slug=${slug}`}
          className="btn border mb-3 mobile-width btn-primary">Upload Results</Link> */}
          <Link
            to={`/upload-watermark?slug=${slug}`}
            className="btn border mb-3 mobile-width btn-primary"
          >
            Upload Watermark
          </Link>
          <Link
            to={`/bulk-import?slug=${slug}`}
            className="btn border mb-3 mobile-width btn-primary"
          >
            Bulk Import
          </Link>
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Name:</span> {event?.eventName}
        </div>
        <div className="mt-2">
          <span style={{ fontWeight: "bold" }}>Description:</span>
          {description}
        </div>
        {event?.id && (
          <div className="mt-2">
            <div className="w-50">
              <BootstrapTable
                keyField="name"
                data={event?.category}
                columns={columns}
                rowStyle={rowStyle}
                classes="text-center"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleEvent;
