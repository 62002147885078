import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ToastComponent from "./Common/Toast";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { baseUrl } from "../apiConfig";
import Race from "./Race";

const EventEdit = () => {
  const navigate = useNavigate();
//const {slug} = useParams();
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId")

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({ width: null, height: null });
  const [showRace, setShowRace] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [giveAways, setGiveAways] = useState({
    'Race T-shirt': false,
    "Finisher's Medal": false,
    'Bib Number with timing chip': false,
    'Goodie Bag': false,
    'Finisher e-Certificate': false,
    'Hydration Support': false,
  });

  const handleGiveAwayChange = (giveAway, checked) => {
    setGiveAways(prevState => ({
      ...prevState,
      [giveAway]: checked,
    }));
  };
  const formik = useFormik({
    initialValues: {
      eventName: "",
      shortName: "",
      location: "",
      aboutEvent: "",
      orgEmail: "",
      contactNum: "",
      secondaryContactNumber: "",
      regOpenDate: "",
      regCloseDate: "",
      tag: "",
      status: "",
      date: "",
      eventPicture: null,
      emailBanner: null,
      categories: [{ name: "", amount: "", minimumAge: "", maximumAge: "", gender: "" , distance: "", description: "" }],
      giveAway: [{name: ""}],
      url: "",
      externalLinkForResults: "",
      race: [""]
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("userId", userId);
formData.append("eventName", values.eventName);
        const slug = values.eventName.replace(/\s+/g, '-');
formData.append("slug", slug);
       formData.append("location", values.location);
        formData.append("date", values.date);
        formData.append("eventPicture", values.eventPicture);
        formData.append("emailBanner", values.emailBanner);
        formData.append("externalLinkForResults", values.externalLinkForResults);
        let response;
      
        response = await axios.post(`${baseUrl}events/create-event-for-results`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      
        if (response.status === 200) {
          setToastVariant("success");
          setToastMessage("Event created successfully")
          setShowToast(true);
          setFormSubmitted(true);
          setTimeout(()=>{
            navigate(`/events`);
          }, 1000)
            
            } else {
        
              const errorData = await response.json();
              console.error('Event creation failed:', errorData);
              setToastVariant("danger");
              setToastMessage("Event creation failed");
              setShowToast(true);
              setFormSubmitted(true);
            }
          }
          catch (error) {
            setToastVariant("danger");
            const errorMessage = error.response?.data?.error || "Error during event creation";
            setToastMessage(errorMessage)
            setShowToast(true);
            setFormSubmitted(true)
            window.scrollTo({top: 0, behavior:"smooth"})
          }
          finally {
            setIsLoading(false);
          }
        },
      });

useEffect(() => {
  const selectedGiveAways = Object.entries(giveAways)
    .filter(([_, checked]) => checked)
    .map(([giveAway]) => ({ name: giveAway }));
  formik.setFieldValue("giveAway", selectedGiveAways);
}, [giveAways]);

  
  useEffect(()=>{
    if(formSubmitted){
  window.scrollTo({top: 0, behavior:"smooth"})
    }
  }, [formSubmitted])



  const handleDayChange = (date) => {
    const utcDate = date && new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    formik.setFieldValue("date", utcDate);
    formik.setFieldTouched('date', false);
  };
  const handleDateBlur = () => {
    formik.setFieldTouched('date', true);
  };

  const handleRegOpenDayChange = (date) => {
    const utcDate = date && new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    formik.setFieldValue("regOpenDate", utcDate);
    formik.setFieldTouched('regOpenDate', false);
  };

  const handleRegOpenBlur = () => {
    formik.setFieldTouched('regOpenDate', true);
  };

  const handleRegCloseDayChange = (date) => {
    const utcDate = date && new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    formik.setFieldValue("regCloseDate", utcDate);
    formik.setFieldTouched('regCloseDate', false);
  };
    const handleRegCloseBlur = () => {
    formik.setFieldTouched('regCloseDate', true);
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    
    if (file) {
      const fileSizeKB = file.size / 1024;
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
       if(fileSizeKB <= 5120){ 
          formik.setFieldValue("eventPicture", file);
          setSelectedFile(file);
          formik.setFieldError("eventPicture", "");
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
          
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              setImageDimensions({width: width, height: height})
            };
          };
          reader.readAsDataURL(file);
        }
        else
        {
          formik.setFieldError("eventPicture", "File size should not be exceeds more than 5MB");
        }
      } else {
        formik.setFieldError("eventPicture", "Please select a valid JPG or PNG file.");
      }
    }
  };
  const handleBannerChange = (event) => {
    const file = event.currentTarget.files[0];
    
    if (file) {
   formik.setFieldValue("emailBanner", file);
          setSelectedFile(file);
          formik.setFieldError("emailBanner", "");
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
          
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              setImageDimensions({width: width, height: height})
            };
          };
          reader.readAsDataURL(file);
        
   
    }
  };

  const handleCategoryChange = (index, key, value) => {
    const updatedCategories = [...formik.values.categories];
    updatedCategories[index][key] = value;

    const isDuplicate = updatedCategories.some(
      (category, i) => i !== index && category.name === value
    );

    if (isDuplicate) {
      formik.setFieldError(
        `categories[${index}].name`,
        "Category Name already exists. Please create category with another name"
      );
    } else {
      formik.setFieldValue("categories", updatedCategories);
    }
  };

  const addCategoryInput = () => {
    const newCategory = { name: "", amount: "", minimumAge: "", maximumAge: "", gender: "", distance: "", description: "" };
    formik.setFieldValue("categories", [
      ...formik.values.categories,
      newCategory,
    ]);
  };

  const removeCategory = (index) => {
    const updatedCategories = [...formik.values.categories];
    updatedCategories.splice(index, 1);
    formik.setFieldValue("categories", updatedCategories);
  };

  const showInputs = () => {
    setShowCategories(true);
  };
  const handleAddRaceBtnClick = () => {
    if (!showRace) setShowRace(true);
    else {
      formik.setFieldValue("race", [...formik.values.race, ""]);
    }
  };
  const [showCategories, setShowCategories] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleChangeLocation = async (e) => {
    const newValue = e.target.value;
    formik.setFieldValue("location", newValue);
    if (newValue.length === 0) {
      setLatitude(null);
      setLongitude(null);
      setSuggestions([]);
    }
    if (newValue.length > 2) {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(newValue)}`);
      const data = await response.json();
      setSuggestions(data);
    }
  };

  const handleSelect = (address, lat, lon) => {
    setLatitude(lat);
    setLongitude(lon);
    formik.setFieldValue("location", address);
    setSuggestions([]);
  };
  return (
    <>
    <style>
      {`
      .datepicker-no-outline:focus {
        outline: none;
      }
      .form-input {
        //position: relative;
      
        //display: flex;
      
        label {
          //position: absolute;
          top: 0;
          margin-top:5px;
          pointer-events: none;
          font-size: 14px;
        }
      
        textarea,
        input {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
        select {
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 0 15px;
          //padding-top: 25px;
          min-height: 50px;
      
          &:focus {
            border: 2px solid #051036 !important;
          }
        }
      
      input:not(:placeholder-shown) {
        padding-top: 0px;
      }
      textarea:not(:placeholder-shown) {
        padding-top: 5px;
      }
        textarea:focus ~ label,
        textarea:valid ~ label,
        input:focus ~ label,
        input:valid ~ label {
          transform: translateY(-10px);
        }
      }
      `}
    </style>
    <div className="container p-4">
    {isLoading ? (
        <div className="loading-overlay text-center d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <div className="spinner-border text-primary">
          </div>
        </div>
      ) : (
        <>
        <div className="row justify-content-center">
      <form
        className="col-xl-100 col-lg-11 mt-30"
        id="reg"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >

        <div className="row x-gap-40 y-gap-20">
          <div className="col-md-6 mt-3">
          <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">Event Display Name</label>
              <input
                type="text"
                id="eventname"
                className="form-control"
                name="eventName"
                value={formik.values.eventName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              </div>
              {formik.touched.eventName && formik.errors.eventName ? (
              <div className="text-danger">{formik.errors.eventName}</div>
            ) : null}
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-group form-input">
            <label className="text-16 text-dark-1 fw-bold">Location</label>
            <input
                type="text"
                id="location"
                name="location"
                value={formik.values.location}
                onChange={handleChangeLocation}
                className="p-2 form-control custom-placeholder"
                onBlur={formik.handleBlur}
                required
          placeholder="Search for a location"
        />
            </div>
            {formik.touched.location && formik.errors.location ? (
              <div className="text-danger">{formik.errors.location}</div>
            ) : null}
                    {formik.values.location && suggestions.length > 0 && (
          <ul>
            {suggestions.map((item) => (
              <li
                key={item.place_id}
                style={{cursor:"pointer"}}
                onClick={() => handleSelect(item.display_name, item.lat, item.lon)}
              >
                {item.display_name}
              </li>
            ))}
          </ul>
        )}
        {latitude && longitude && 
<div className="mt-3 iframe-container">
<iframe
  src={`https://maps.google.com/maps?q=${encodeURIComponent(formik.values.location)}&output=embed&ll=${latitude},${longitude}&z=8&iwloc`}
  width="100%"
  height="450"
  allowfullscreen=""
  loading="lazy"
></iframe>
</div>
}
</div>

          <div className="col-md-6 mt-3">
            <div className="form-input">
             <label className="text-16 text-dark-1 fw-bold">Event Date</label>
             </div>
             <div className="form-control" style={{lineHeight:"40px"}}>
              <DatePicker
                selected={formik.values.date}
                onChange={handleDayChange}
                dateFormat="dd/MM/yyyy"
                className="border-0 datepicker-no-outline"
                onBlur={handleDateBlur}
              /> 
 </div>
            {formik.touched.date && formik.errors.date ? (
              <div className="text-danger">{formik.errors.date}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <div>
              <label className="text-16 text-dark-1 fw-bold mt-3">Event Poster</label>
              </div>
              <input
              className="text-dark-1"
                type="file"
                id="eventpicture"
                name="eventPicture"
                onChange={handleFileChange}
                onBlur={formik.handleBlur}
              />
             
               {selectedFile && (
          <div className="d-flex flex-inline x-gap-10">
            <p>Size: {Math.round(selectedFile.size / 1024)} KB;</p>
            <p>Width: {imageDimensions.width};</p>
            <p>Height: {imageDimensions.height}</p>
            
          </div>
        )}
              {formik.touched.eventPicture && formik.errors.eventPicture ? (
                <div className="text-danger">{formik.errors.eventPicture}</div>
              ) : null}
          </div>
          <div className="col-md-6">
          <div>
              <label className="text-16 text-dark-1 fw-bold mt-3">Email Banner</label>
              </div>
            <input
            className="text-dark-1"
              type="file"
              id="emailbanner"
              name="emailBanner"
              onChange={handleBannerChange}
              onBlur={formik.handleBlur}
            />
              {formik.touched.emailBanner && formik.errors.emailBanner ? (
                <div className="text-danger">{formik.errors.emailBanner}</div>
              ) : null}
          </div>
<div className="col-md-6 mt-3">
            <div className="form-input">
            <label className="text-16 text-dark-1 fw-bold">
                External Link For Results
              </label>
              <input
                type="text"
                id="externalLinkForResults"
                className="form-control"
                name="externalLinkForResults"
                value={formik.values.externalLinkForResults}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
</div>
    </div>
        <div className="row x-gap-20 y-gap-20 pt-10">
          <div className="col-12">
            <button
            className="button border btn btn-primary px-4 py-2 text-light-1 rounded-3 mt-3" 
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      </div>
</>
      )}
      <ToastComponent
      showToast={showToast}
      toastHeader="Event Create"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />

      
      </div>
 </>
  );
};

export default EventEdit;
