/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import DashboardHeader from "../DashboardHeader";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import BreadCrumb from "../BreadCrumb";
import BootstrapTable from "react-bootstrap-table-next";
import Sidebar from "../Sidebar";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { getEvents } from "../../api/events";
import BlockingLoader from "../Common/Loader";
import { getBulkImports } from "../../api/bulkimport";
import moment from "moment";

const BulkImportsList = () => {
  const { slug } = useParams();

  const [events, setEvents] = useState([]);
  const [bulkImports, setBulkImports] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
const [selectedEvent, setSelectedEvent] = useState("");
const [filteredBulkImports, setFilteredBulkImports] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [bulkImportLoading, setBulkImportLoading] = useState("");

  const [eventsError, setEventsError] = useState("");
  const [bulkImportError, setBulkImportError] = useState("");

  const fetchEvents = useCallback(async () => {
    try {
      setEventsError("");
      setEventsLoading(true);
      const response = await getEvents();
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      setEventsError(error.message);
    } finally {
      setEventsLoading(false);
    }
  }, []);

  const fetchBulkImports = async () => {
    try {
      setBulkImportError("");
      setBulkImportLoading(true);
      const response = await getBulkImports();
      const responseData = Array.isArray(response.data) ? response.data : [];
setBulkImports(responseData);
    } catch (error) {
      console.error("Error event by slug data:", error);
      setBulkImportError(error.message);
    } finally {
      setBulkImportLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchBulkImports();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  function downloadbulkImport(row) {
    fetch(row.url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", row.bulkImportName);
        document.body.appendChild(link);
        link.click();
          document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error("Error downloading the file:", error));
  }
  
  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MM-YYYY HH:mm");
  };
  const columns = [
    {
      dataField: "bulkImportName",
      text: "Bulk Import Name",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "numberOfEntries",
      text: "Number of entries",
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: "createdAt",
      text: "Imported At",
      formatter: (cell) => formatDate(cell),
      headerStyle: {
        verticalAlign: "middle",
        textAlign: "center",
        width: "300px",
      },
    },
    {
      dataField: "eventName",
      text: "Event",
      filter: selectFilter({
        options: bulkImports.reduce((acc, item) => {
          acc[item.eventName] = item.eventName;
          return acc;
        }, {}),
        placeholder: "Select Event",
        onFilter: (filterValue) => {
          setSelectedEvent(filterValue);
        },
        style: {
          width: "auto",
          marginLeft: "3px",
        },
        className: "form-select",
      }),
      headerStyle: {
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        textAlign: "center",
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="d-flex justify-content-center">
        <div
          className="download-text"
          onClick={() => downloadbulkImport(row)}
        >
          Download
        </div>
      </div>),
      headerStyle: {
        verticalAlign: 'middle',
        textAlign: 'center',
      },
    }, 
   ];

  const rowStyle = (row, rowIndex) => {
    return {
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      width: "auto",
      textAlign: "center",
    };
  };
useEffect(()=> {
  if (selectedEvent) {
    const filtered = bulkImports.filter(
      (importItem) => importItem.eventName === selectedEvent
    );
    setFilteredBulkImports(filtered);
  } else {
    setFilteredBulkImports(bulkImports);
  }
}, [bulkImports, selectedEvent])
  return (
    <>
      <style>
        {`
          .sticky-header th {
            position: sticky;
            top: 0;
            background-color: #fff; 
            z-index: 1; 
               white-space:nowrap;
          }
          .react-bootstrap-table td{
            padding-left: 1em;
            white-space:nowrap;
          }
        `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      {(eventsLoading || bulkImportLoading) && <BlockingLoader />}
      <div className="content">
        <BreadCrumb task={"Bulk Imports"} />
        {bulkImports.length > 0 ? (
          <>
           <div className="mt-3">Total: <span style={{fontWeight: "bold"}}>{filteredBulkImports.length}</span></div>
           <PaginationProvider
           pagination={ paginationFactory({
             custom: true,
             sizePerPage:20,
totalSize: filteredBulkImports.length
           }) }
         >
           {
             ({
               paginationProps,
               paginationTableProps
             }) => (
               <div>
                <div style={{ overflowX:"auto", marginTop:"15px"}}>
                 <PaginationListStandalone
                   { ...paginationProps }
                 />
                 </div>
          <div
            style={{ maxHeight: "450px", overflowY: "auto" }}
            className="table-responsive pb-4"
          >
            <BootstrapTable
              keyField="id"
              data={filteredBulkImports}
              columns={columns}
              rowStyle={rowStyle}
              filter={filterFactory()}
              headerClasses="sticky-header"
              { ...paginationTableProps }
              bootstrap4
              striped
              hover
              condensed
            />
          </div>
          </div>
    )
  }
</PaginationProvider>
</>
        ) : <h3 className="text-center fw-bold mt-3">No data available</h3>}
      </div>
      {eventsError && <p className="error-message">{eventsError}</p>}
      {bulkImportError && (
        <p className="error-message">{bulkImportError}</p>
      )}
    </>
  );
};

export default BulkImportsList;
