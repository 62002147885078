import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./DashboardHeader";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router-dom";
import { getEvents } from "../api/events";
import { updateEventStatus } from "../api/events";
import BlockingLoader from "./Common/Loader";
import Pagination from "./Pagination";
import ToastComponent from "./Common/Toast";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBCheckbox } from 'mdb-react-ui-kit';
import moment from "moment";
const Events = () => {
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEvents] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState(() => {
    const storedStatuses = JSON.parse(localStorage.getItem('selectedStatuses'));
    return storedStatuses && storedStatuses.length > 0 ? storedStatuses : ["OPENFORREGISTRATION"];
  });
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState({
    date: "",
    createdAt: "",
    eventName: ""
  });
  const statusLabels = {
    "DRAFT": "Draft",
    "UPCOMING": "Upcoming",
    "OPENFORREGISTRATION": "Open for Registration",
    "REGISTRATIONCLOSED": "Registration Closed",
    "EVENTDATEEXPIRED": "Event Date Expired",
    "RESULTSUPLOADED": "Results Uploaded",
    "PHOTOSUPLOADED": "Photos Uploaded",
    "ARCHIVED": "Archived"
  };
  const statuses = Object.keys(statusLabels);
  const handleStatusSelect = (status) => {
    setSelectedStatuses((prev) => {
      const updatedStatuses = prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status];
      localStorage.setItem('selectedStatuses', JSON.stringify(updatedStatuses));
      return updatedStatuses;
    });
  };
  const sortEvents = (field, order) => {
    const sortedEvents = events?.sort((a, b) => {
      if (order === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setEvents(sortedEvents);
  };
  const handleSortSelection = (field) => {
    setSortField(field);
    const newOrder = sortOrder[field] === "asc" ? "desc" : "asc";
    setSortOrder((prev) => ({
      ...prev,
      [field]: newOrder
    }));
    sortEvents(field, newOrder);
    console.log(sortOrder[field], "sortOrder[field]")
  };
  const fetchData = async () => {
    // const response = await axios.get(`${baseUrl}events/get-results`);
    try {
      setError("");
      setLoading(true);
      const statusString = selectedStatuses.join(",");
      const response = await getEvents({status: statusString, page: currentPage, perPage: 10});/*axios.get(`${baseUrl}events/get-results?userId=${userId}`
  , {
      params: {
      page: currentPage,
        perPage: 10,
      },
    }); */
    const totalCountHeader = response.headers.get('x-total-count');
    console.log('Total Count Header:', totalCountHeader);
    const totalCount = parseInt(totalCountHeader, 10);
        setEvents(response.data);
        setTotalEvents((prevTotal) => totalCount || prevTotal);
    } catch (error) {
      console.log("Error during fetching events", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const [toggleStates, setToggleStates] = useState({});

  const handleToggleMenu = (itemId) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, selectedStatuses]);
  console.log(events);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  const handleTag = (itemId) => {
    const updatedEvents = events.map((event) => {
      if (event.id === itemId) {
        return {
          ...event,
          tag: event.tag === "Open" ? "Closed" : "Open",
        };
      }
      return event;
    });
    setEvents(updatedEvents);
  };

  const handleEventUpdate = async (itemId, updateType, value) => {
    try {
      setLoading(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      const updateEvent = updateType === 'status' ? { status: value } : { showOnWebsite: value };
      if (updateType === "status") {
        updateEvent.tag = value === "OPENFORREGISTRATION" ? "Open" : "Closed";
      }
      const response = await updateEventStatus(
        {eventId: itemId},
        updateEvent
      );
  
      if (response.status === 200) {
        const updatedEvents = events.map((event) => {
          if (event.id === itemId) {
            return {
              ...event,
              [updateType]: value,
              ...(updateType === "status" && { tag: updateEvent.tag }),
            };
          }
          return event;
        });
  
        const filteredEvents = updatedEvents.filter(event => selectedStatuses.includes(event.status));
      setEvents(filteredEvents);
        setToastMessage(`Event ${updateType === 'status' ? 'status' : 'visibility'} updated successfully`);
        setToastVariant("success");
        window.scrollTo({top: 0, behavior:"smooth"});
      }
    } catch (error) {
      console.error(`Error updating ${updateType === 'status' ? 'status' : 'visibility'}:`, error);
      setToastMessage(`Error updating event ${updateType === 'status' ? 'status' : 'visibility'}`);
      setToastVariant("danger");
      window.scrollTo({top: 0, behavior:"smooth"});
    } finally {
      setLoading(false);
      setShowToast(true);
    }
  };
  useEffect(() => {
    const handleUnload = (event) => {
      localStorage.removeItem('selectedStatuses');
    };
  
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);
  return (
    <>
      <style>
        {`
      
      .toggle {
       position:relative; 
       cursor:pointer;
      }
      .dropdown-items {
       display:flex;
       flex-direction:column;
       position:absolute;
       right:0px;
       width:210px;
       z-index:1;
       white-space:nowrap;
      }
   
    .dropdown-items :hover {
      color: white;
      background-color:#697488;
       white-space:nowrap;
       border-radius:3px;
  }
      .dropdown-item{
        border-bottom: 1px solid #e5f0fd;
        padding-left: 0.2em;
        }
        @media (max-width: 768px) {
  .center-mobile {
    justify-content: center;
    align-items: center;
  }
}
    `}
      </style>
      <div className="header-margin"></div>

      <DashboardHeader handleToggle={handleToggle} />

      <Sidebar isOpen={isOpen} handleToggle={handleToggle} />
      {loading && <BlockingLoader />}
      <div className="content p-4">
      <div className="d-flex gap-3 flex-md-row flex-column justify-content-end center-mobile">
      <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              style={{ background: "#163c8c", border: "none" }}
            >
              Sorter
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ border: "1px solid #e5f0fd", marginTop: "10px" }}>
              <Dropdown.Item onClick={()=> handleSortSelection("date")}>
                <div className="d-flex gap-2 align-items-center">
                  {console.log(sortOrder.date, "sortOrder.date")}
                {sortField === "date" && sortOrder.date === "asc" ? <i className="bi bi-sort-up"></i> : <i className="bi bi-sort-down"></i>}
                  <div>Event Date</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={()=> handleSortSelection("createdAt")}>
                <div className="d-flex gap-2 align-items-center">
                {sortField === "createdAt" && sortOrder.createdAt === "asc" ? <i className="bi bi-sort-up"></i> : <i className="bi bi-sort-down"></i>}
                  <div>Event Creation Date</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={()=> handleSortSelection("eventName")}>
                <div className="d-flex gap-2 align-items-center">
                {sortField === "eventName" && sortOrder.eventName === "asc" ? <i className="bi bi-sort-alpha-up-alt"></i> : <i className="bi bi-sort-alpha-down"></i>}
                  <div>Event Name (A-Z)</div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        <MDBDropdown>
          <MDBDropdownToggle tag="button" className="btn border" style={{backgroundColor: "#163c8c", color:"white"}}>
            Select Status
          </MDBDropdownToggle>
          <MDBDropdownMenu style={{padding:"0.5em", whiteSpace:"nowrap", marginTop:"10px"}}>
            {statuses.map((status) => (
              <MDBDropdownItem key={status}>
                <MDBCheckbox
                  label={statusLabels[status]}
                  value={status}
                  checked={selectedStatuses.includes(status)}
                  onChange={() => handleStatusSelect(status)}
                />
              </MDBDropdownItem>
            ))}
          </MDBDropdownMenu>
        </MDBDropdown>
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              style={{ background: "#163c8c", border: "none" }}
            >
              Create Event
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ border: "1px solid #e5f0fd", marginTop: "10px" }}>
              <Dropdown.Item href={`/event-edit?userId=${userId}`}>
                <div className="d-flex gap-2 align-items-center">
                  <div>Event Ror Registration</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href={`/create-event?userId=${userId}`}>
                <div className="d-flex gap-2 align-items-center">
                  <div>Event For Results</div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="mt-4 row y-gap-20 justify-content-center items-center">
          <div className="row y-gap-30">
            {events.map((item) => (
              <div className="col-lg-3 col-sm-6" key={item?.id}>
                <div className="hotelsCard__image">
                  <div className="cardImage inside-slider">
                    <Link
                      to={`/event/${item.slug}`}
                      className="hotelsCard -type-1 hover-inside-slider ratio"
                    >
                      <img
                        src={item?.eventPicture}
                        className="img-thumbnail"
                        alt="Event"
                      />
                    </Link>
                    <div className="cardImage__leftBadge">
                      <div
                        className={`px-20 py-1 rounded-right-4 text-12 lh-16 fw-500 uppercase ${
                          isTextMatched(item?.tag, "Open")
                            ? "bg-dark-1 text-white"
                            : ""
                        } ${
                          isTextMatched(item?.tag, "Closed")
                            ? "bg-blue-1 text-white"
                            : ""
                        } 
                    } ${
                      isTextMatched(item?.tag, "Fast Filling")
                        ? "bg-brown-1 text-white"
                        : ""
                    } 
                    `}
                      >
                        {item?.tag}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hotelsCard__content">
                  <div className="d-flex flew-row justify-content-between mt-2">
                    <div>
                      <Link
                        to={`/event/${item.slug}`}
                        className="hotelsCard -type-1"
                      >
                        <h5 className="hotelsCard__title text-14 fw-500">
                          <span>{item?.eventName}</span>
                        </h5>
                        <p className="text-14">{item?.location}</p>
                        <p className="text-14">{moment(item?.date).format('MMMM D, YYYY')}</p>
                      </Link>
                    </div>
                    <div
                      className="toggle"
                      onClick={() => handleToggleMenu(item.id)}
                    >
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2em"
                          height="2em"
                          viewBox="0 0 256 256"
                        >
                          <path
                            fill="currentColor"
                            d="M144 128a16 16 0 1 1-16-16a16 16 0 0 1 16 16m-84-16a16 16 0 1 0 16 16a16 16 0 0 0-16-16m136 0a16 16 0 1 0 16 16a16 16 0 0 0-16-16"
                          ></path>
                        </svg>
                      </div>
                      {toggleStates[item.id] && (
                        <div className="dropdown-items bg-white border">
                          <div className="dropdown-item" onClick={(e) => {
                            handleTag(item?.id);
                              e.stopPropagation();
                            }}>
                            {item?.tag === "Open" ? "Close" : "Open"}
                          </div>
                          <div></div>
                            <Link
                              to={`/event-edit?slug=${item?.slug}`}
                              state={{ item }}
                              className="link dropdown-item"
                            >
                              Edit
                            </Link>
                          <div></div>
                            <div className="dropdown-item">
            <div className="form-check form-switch">
            <input
  className="form-check-input"
  style={{cursor:"pointer"}}
  type="checkbox"
  id={`formSwitchChecked-${item.id}`}
  checked={item.showOnWebsite}
  onChange={(e) => {
    e.stopPropagation();
    handleEventUpdate(item.id, "showOnWebsite", !item.showOnWebsite);
  }}
/>
<label className="form-check-label" htmlFor={`formSwitchChecked-${item.id}`}>{item.showOnWebsite ? "On" : "Off"} (Show on website)</label>
    </div>
    </div>
                          <div></div>
               <div
                className="dropdown-item"  
    onClick={(e) => {
      e.stopPropagation();
      handleEventUpdate(item.id, "status", "OPENFORREGISTRATION");
    }}
  >
    Open For Registration
  </div>
  <div></div>
  <div
                className="dropdown-item"  
    onClick={(e) => {
      e.stopPropagation();
      handleEventUpdate(item.id, "status", "REGISTRATIONCLOSED");
    }}
  >
    Registrations Closed
  </div>
  <div></div>
  <div
    className="dropdown-item" 
    onClick={(e) => {
      e.stopPropagation();
      handleEventUpdate(item.id, "status", "RESULTSUPLOADED");
    }}
  >
    Results Uploaded
  </div>
  <div></div>
  <div
 className="dropdown-item" 
    onClick={(e) => {
      e.stopPropagation();
      handleEventUpdate(item.id, "status", "PHOTOSUPLOADED");
    }}
  >
    Photos Uploaded
  </div>
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
    
        </div>
        {error ? <p className="error-message">{error}</p> : null}
        {totalEvents > 10 &&
            <Pagination 
            totalEvents={totalEvents}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            />
}
      <ToastComponent
      showToast={showToast}
      toastHeader="Event Status Updation"
      setShowToast={setShowToast}
      toastMessage={toastMessage}
      toastVariant={toastVariant}
      />
      </div>
    </>
  );
};

export default Events;

function isTextMatched(tag, match) {
  if (tag && match && typeof tag === "string" && typeof match === "string") {
    const lowercaseTag = tag.toLocaleLowerCase();
    const lowercaseMatch = match.toLocaleLowerCase();

    return lowercaseTag === lowercaseMatch;
  }
  return false;
}
