import React, { useState, useEffect } from 'react'
import PaymentSettlements from './PaymentSettlements'
import DashboardHeader from "../DashboardHeader";
import BreadCrumb from "../BreadCrumb";
import Sidebar from "../Sidebar";
import { getEvents } from "../../api/events";
import { getSettlements } from "../../api/settlements";
import BlockingLoader from "../Common/Loader";
const Index = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [settlements, setSettlements] = useState([]);
    const [settlementsLoading, setSettlementsLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState("");
  
    const fetchData = async () => {
      try {
        let allEvents = [];
        let currentPage = 1;
        const perPage = 10;
        let totalEvents = 0;
        const response = await getEvents({ page: currentPage, perPage });
        const totalCountHeader = response.headers.get('x-total-count');
        console.log('Total Count Header:', totalCountHeader);
    
        totalEvents = parseInt(totalCountHeader, 10);
        const totalPages = Math.ceil(totalEvents / perPage);
        console.log(`Total Pages to fetch: ${totalPages}`);
  
        allEvents = response.data;
        while (currentPage < totalPages) {
          currentPage++;
          const pageResponse = await getEvents({ page: currentPage, perPage });
          allEvents = allEvents.concat(pageResponse.data);
        }
        setEvents(allEvents);
      } catch (error) {
        console.log("Error during fetching events", error);
      }
    };
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleEventChange = (e) => {
      setSelectedEvent(e.target.value);
    };
  const event = events?.find(item =>item?.eventName === selectedEvent);
  const fetchSettlements = async ()=> {
    try {
      setSettlementsLoading(true);
      const response = await getSettlements({
        eventId: event.id
      });
      setSettlements(response.data);
    } catch (error) {
      console.error("Error fetching settlements:", error);
    }
    finally{
      setSettlementsLoading(false);
    }
  }
  useEffect(()=> {
    fetchSettlements();
  }, [selectedEvent])
    const handleToggle = () => {
        setIsOpen(!isOpen);
        console.log("Sidebar toggled");
      };
  return (
    <>
        <div className="header-margin"></div>
<DashboardHeader handleToggle={handleToggle} />
<Sidebar isOpen={isOpen} handleToggle={handleToggle} />

<div className="content">
<BreadCrumb task={"Settlements"} />
{settlementsLoading && (
        <BlockingLoader />
)}
<PaymentSettlements
selectedEvent={selectedEvent}
handleEventChange={handleEventChange}
events={events}
settlementsLoading={settlementsLoading}
settlements={settlements} />
</div>
    </>
  )
}

export default Index;