import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../apiConfig";
import "react-datepicker/dist/react-datepicker.css";
import { Container } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import BlockingLoader from "./Common/Loader";



const AddFields = ({ slug, eventId }) => {
  const [event, setEvent] = useState(null);
  const [columnNames, setColumnNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [race, setRace] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [additionalFields, setAdditionalFields] = useState({});
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({
    distance: "",
    split: "",
    speed: "",
    pace: "",
    time: "",
    rank: ""
  });

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEvent();
  }, [slug]);

  useEffect(() => {
    const fetchColumnNames = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseUrl}events/get-column-names?eventId=${eventId}&race=${race}`
        );
        setColumnNames(response.data.columnNames);
      } catch (error) {
        console.error("Error fetching column names:", error);
      }
      setLoading(false);
    };

    if (race) {
      fetchColumnNames();
    }
  }, [race, eventId]);


  const handleSaveEdit = async (index, btn) => {
    delete additionalFields.isEdit;
    columnNames[index] = {...additionalFields};
    await updateColumnNames(columnNames);
  }


  const handleCancelEdit = async (index) => {
    const updateColumnNames = [...columnNames];
    delete updateColumnNames[index].isEdit;
    if(updateColumnNames[index].isAdd){
      updateColumnNames.splice(index, 1);
    }
    setColumnNames(updateColumnNames);
    setAdditionalFields({});
  }

  const updateColumnNames = async (columnNames) => {
    setLoading(true);
    const response = await axios.put(
      `${baseUrl}events/update-column-names?eventId=${eventId}`,
      {
        raceColumnNames: columnNames,
        race
      }
    );
    setLoading(false);
    setColumnNames(response.data.columnNames);
    setAdditionalFields({});
    
  }

  const removeColumnName = async (index) => {
    if (!race) {
      setErrorMessage("Please select a race before removing a column name.");
      return;
    }
    columnNames.splice(index,1);
    setLoading(true);
    try {
      const response = await axios.put(
        `${baseUrl}events/update-column-names?eventId=${eventId}`,
        {
          raceColumnNames: columnNames,
          race
        }
      );

      setColumnNames(response.data.columnNames);
      setErrorMessage("");
    } catch (error) {
      console.error("Error removing column name:", error);
      setErrorMessage("Failed to remove column name.");
    }
    setLoading(false);
  };

  // Function to add or update a column name
  const addColumnName = async (fieldData, editMode = false) => {
    if (!race) {
      setErrorMessage("Please select a race before adding a column name.");
      return;
    }

    try {
      const response = await axios.put(
        `${baseUrl}events/update-column-names?eventId=${eventId}`,
        { isAdd: !editMode, columnName: fieldData, race }
      );
      setColumnNames(response.data.columnNames);
      setAdditionalFields([]);
      setEditIndex(null);
    } catch (error) {
      console.error("Error updating column names:", error);
    }
  };

  const handleAddField = () => {
    const isActiveEdit = columnNames.find(ele => (ele.isEdit));
    if(isActiveEdit){
      setErrorMessage('Save or Cancel the active change...')
    } else {
      const updatedColumnNames = [...columnNames];
      updatedColumnNames.push({ distance: "", split:"", speed:"",pace: "", time:"", rank: "", isEdit: true, isAdd: true, });
      setColumnNames(updatedColumnNames);
      setAdditionalFields({ distance: "", split:"", speed:"",pace: "", time:"", rank: "", isEdit: true, isAdd: true});
    }
    
  };

  // Function to remove an input field
  const handleRemoveField = (index) => {
    setAdditionalFields((prevFields) =>
      prevFields.filter((_, i) => i !== index)
    );
  };

  // Function to handle input changes in dynamic fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedAdditionalFields = {...additionalFields,
      [name]: value,
    };
    setAdditionalFields(updatedAdditionalFields);
  };

  // Function to save a new column name
  const handleSaveField = (index) => {
    const fieldData = additionalFields[index];
    if (
      !fieldData.distance ||
      !fieldData.split ||
      !fieldData.speed ||
      !fieldData.pace ||
      !fieldData.time ||
      !fieldData.rank
    ) {
      setAdditionalFields((prevFields) =>
        prevFields.map((field, i) =>
          i === index
            ? { ...field, error: "Please fill in all fields." }
            : field
        )
      );
      return;
    }
    addColumnName(fieldData);
  };

  // Function to handle editing an existing column
  const handleEditClick = (index) => {
    const isActiveEdit = columnNames.find(ele => (ele.isEdit));
    if(isActiveEdit){
      setErrorMessage('Save or Cancel the active change...')
    } else {
      const updatedColumns = [...columnNames];    
      updatedColumns[index].isEdit = true;
      setAdditionalFields(updatedColumns[index]);
      setColumnNames(updatedColumns);    
    }
    
  };

  // Function to update an existing column name
  const handleUpdateField = () => {
    if (
      !editData.distance ||
      !editData.split ||
      !editData.speed ||
      !editData.pace ||
      !editData.time
    ) {
      setErrorMessage("Please fill in all fields before updating.");
      return;
    }

    addColumnName(editData, true);
  };

  return (
    <Container className="content">
    { loading && <BlockingLoader /> }
      <div className="p-3">
        <div className="row y-gap-20 justify-center items-center">
          <div className="col-xl-10 col-lg-12 mt-30 mx-auto absolute">
            <div className="d-flex flex-column col justify-content-center align-items-center">
              <div className="col-md-8 mt-4">
                <label className="text-16 text-light-1 mb-2">
                  Select the Race
                </label>
                <select
                  className="form-control"
                  style={{ appearance: "auto" }}
                  value={race}
                  required
                  onChange={(event) => {
                    setRace(event.target.value);
                    setErrorMessage("");
                  }}
                >
                  <option value="">Select the race</option>
                  {event &&
                    event.race.map((race, index) => (
                      <option key={index} value={race}>
                        {race}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-xl-12 col-xxl-12 mt-4">
                <label className="text-16 text-light-1 mb-2">
                  Add Column Name
                </label>
                <div className="row">
                  <div className="col-md-2 offset-md-10 mb-3">
                    {race && (
                      <button
                        className="btn btn-secondary"
                        onClick={handleAddField}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>

                {/* ------Update data----------- */}
                {editIndex !== null && (
                  <div className="row mt-4">
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="distance"
                        placeholder="distance"
                        value={editData.distance}
                        onChange={(e) =>
                          setEditData({ ...editData, distance: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="split"
                        placeholder="split"
                        value={editData.split}
                        onChange={(e) =>
                          setEditData({ ...editData, split: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="speed"
                        placeholder="split speed"
                        value={editData.speed}
                        onChange={(e) =>
                          setEditData({ ...editData, speed: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="pace"
                        placeholder="split pace"
                        value={editData.pace}
                        onChange={(e) =>
                          setEditData({ ...editData, pace: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="time"
                        placeholder="split tod"
                        value={editData.time}
                        onChange={(e) =>
                          setEditData({ ...editData, time: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        className="form-control"
                        type="text"
                        name="rank"
                        placeholder="split rank"
                        value={editData.rank}
                        onChange={(e) =>
                          setEditData({ ...editData, rank: e.target.value })
                        }
                      />
                    </div>
                    <div
                      className="col-md-2 d-flex align-items-start"
                      style={{ marginLeft: "-6px" }}
                    >
                      <button
                        className="btn btn-success"
                        onClick={handleUpdateField}
                      >
                        Update
                      </button>

                      <button
                        className="btn btn-danger"
                        onClick={() => setEditIndex(null)}
                        style={{ marginLeft: "6px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                {/* ----------------- */}

                {/* ------split data--------- */}
                {columnNames && columnNames.length > 0 && (
                  <div className="row" style={{ marginTop: "40px" }}>
                  <div
                  className="col-lg-10 col-md-12 d-flex align-items-center">                    
                    <table className="table">
                      <thead>
                        <tr><th>Distance</th><th>Split</th><th>Speed</th><th>Pace</th><th>Time</th><th>Rank</th><th></th></tr>
                      </thead>                    
                    <tbody>
                    {columnNames.map((column, index) => {
                      return column.isEdit ? (
                        
                          <tr>
                            
                              <td><input className="form-control" name="distance" onChange={handleInputChange} value={additionalFields.distance} /></td> 
                              <td><input className="form-control" name="split" onChange={handleInputChange} value={additionalFields.split} /></td> 
                              <td><input className="form-control" name="speed" onChange={handleInputChange} value={additionalFields.speed} /></td> 
                              <td><input className="form-control" name="pace" onChange={handleInputChange} value={additionalFields.pace} /></td> 
                              <td><input className="form-control" name="time" onChange={handleInputChange} value={additionalFields.time} /></td> 
                              <td><input className="form-control" name="rank" onChange={handleInputChange} value={additionalFields.rank} /></td> 
                              <td> <div className="btn-group"  style={{"padding-left":"15px"}}>
                                  <input className="btn btn-sm btn-success" type="button" name="save" value="Save" onClick={() => handleSaveEdit(index)} />
                                  <input className="btn btn-sm btn-warning" type="button" name="cancel" onClick={() => handleCancelEdit(index)} value="Cancel" />
                                  </div>
                              </td>                             
                          </tr>
                          ): (
                            <>
                              <tr>
                                <td>{column.distance}</td>
                                <td>{column.split}</td>
                                <td>{column.speed}</td>
                                <td>{column.pace}</td>
                                <td>{column.time}</td>
                                <td>{column.rank}</td>
                                <td>
                                  <div class="btn-group" style={{"padding-left":"15px"}}>
                                  <input className="btn btn-sm btn-primary" type="button" value="Edit" onClick={() => handleEditClick(index)} />
                                  <input className="btn btn-sm btn-danger" type="button" value="Remove" onClick={() => removeColumnName(index)} />
                                  </div>
                                </td>
                              </tr>
                            </>
                    )})}
                          </tbody>
                          </table>
                        
                      </div>
                    
                  </div>
                )}
 <h3>Verify columns:</h3>
<ol>
<li>Place</li>
<li>Name</li>
<li>Bib</li> 
<li>Distance</li> 
<li>Category</li> 
<li>Gender (Male, Female)</li>
<li>Chip time</li>
<li>Overall Pace</li>
<li>Overall Speed</li>
<li>Gender Rank</li>
<li>Age Category Rank</li> 
</ol>

                <Toast
                  show={errorMessage ? true: false}
                  onClose={() => setErrorMessage("")}
                  delay={3000}
                  autohide
                  className={`bg-danger align-items-top text-white bg-primary border-0`} 
                  style={{"position": "absolute", "left": "50%", "top": "50%"}}
                >
                 
                  <Toast.Body>{errorMessage}</Toast.Body>
                </Toast>
                {/* ------split data--------- */}
              </div>
            </div>
          </div>
        </div>  
      </div>
    </Container>
  );
};

export default AddFields;
