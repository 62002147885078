import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { useParams } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import DashboardHeader from "../DashboardHeader";
import SidebarEvent from "../SidebarEvent";
import CouponsPerEvent from "./CouponsPerEvent";
import CouponsUsedPerCategory from "./CouponsUsedPerCategory";
import IndividualCouponRunner from "../IndividualCouponRunner";
import CreateCouponByDiscountPercentage from "./CreateCouponByDiscountPercentage";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Sidebar from "../Sidebar";
import BlockingLoader from "../Common/Loader";

const UsageCoupon = () => {
  const { slug } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };
  const [event, setEvent] = useState(null);
  const [firstTab, setFirstTab] = useState(false);
  const [secondTab, setSecondTab] = useState(false);
  const [thirdTab, setThirdTab] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}events/get-eventbyslug?slug=${slug}`
        );
        setEvent(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);
  const handleTabSelect = async (key) => {
    if (key === "first") {
      setFirstTab(true);
    } else if (key === "second") {
      setSecondTab(true);
    } else if (key === "third") {
      setThirdTab(true);
    }
  };
  console.log(isOpen, "isOpen");
  return (
    <>
      <div className="header-margin"></div>
      <DashboardHeader handleToggle={handleToggle} />
      <Sidebar isOpen={isOpen} handleToggle={handleToggle} slug={slug} />
      {loading && <BlockingLoader/>}
      <div className="content">
        <BreadCrumb event={event} slug={slug} task={"Usage"} />

        <div className="bg-white shadow-xl rounded-md p-3 mb-6 no-scrollbar">
          <div style={{ display: "block", padding: 10 }}>
            <Tabs defaultActiveKey="four" onSelect={handleTabSelect}>
              <Tab eventKey="first" title="Coupons generted per event">
                <CouponsPerEvent
                  slug={slug}
                  firstTab={firstTab}
                  setFirstTab={setFirstTab}
                  setLoading={setLoading}
                />
              </Tab>
              <Tab eventKey="second" title="Coupons used per category">
                <CouponsUsedPerCategory
                  secondTab={secondTab}
                  setSecondTab={setSecondTab}
                  slug={slug}
                />
              </Tab>

              <Tab eventKey="third" title="Individual details">
                <IndividualCouponRunner thirdTab={thirdTab} slug={slug} />
              </Tab>

              <Tab
                eventKey="four"
                title="Coupon create based on discount percentage"
              >
                <CreateCouponByDiscountPercentage
                 slug={slug}
                 setLoading={setLoading}
                 />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsageCoupon;
