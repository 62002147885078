import React, { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { headerStyle } from "../../utils/tableStyles";

const PaymentSettlements = ({selectedEvent, handleEventChange, events, settlements}) => {
  const tableHeaders = [
    {
      dataField: "categoryname",
      text: <div>Category</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "totalfeeamount",
      text: <div>Total Fee Amount</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "discountamount",
      text: <div>Discount Amount</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "novaracefee",
      text: <div>Novarace Fee</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "paymentgatewaycharges",
      text: <div>Payment Gateway Charges</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "gst",
      text: <div>Gst</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "totalamountpaidafterdiscount",
      text: <div>Total Amount Paid After Discount</div>,
      headerStyle: headerStyle,
    },
    {
      dataField: "ordercount",
      text: <div>Order Count</div>,
      headerStyle: headerStyle,
    },
  ];

  const rowStyle = {
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    textAlign: "center",
    lineHeight:"1em",
  }; 
  return (
      <>
    <div className="d-flex align-items-center justify-content-center my-3">
        <select
          className="form-select"
          id="event"
          name="event"
          value={selectedEvent}
          onChange={handleEventChange}
          required
          style={{ width: "300px" }}
        >
          <option value="">Select Event</option>
          {events?.map((event) => (
            <option key={event.id} value={event.eventName}>
              {event.eventName}
            </option>
          ))}
        </select>
      </div>
     { selectedEvent && Array.isArray(settlements) && settlements.length > 0 ? (
<div style={{ maxHeight: "450px" }} className="table-responsive">
<BootstrapTable
keyField="id"
data={settlements}
columns={tableHeaders}
rowStyle={rowStyle}
striped
hover
condensed
/>
</div>
) : selectedEvent ? (
<h3 className="text-center">No data available</h3>
) : ""
}
  </>
  )
}

export default PaymentSettlements;